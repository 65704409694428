import React from 'react'
import * as cls from 'classnames'
import { Link } from 'gatsby'
import * as styles from './navigation.module.css'
import links from '../../constants/links'
import DonateButton from '../donate-button'
import DSALogo from './dsaLogo'

const NavigationItems = () => (
  <ul className={styles.navigationItems}>
    {links.map((link, index) => (
      <li key={`nav-${index}`} className={styles.navigationItem}>
        <Link to={link.url}>{link.label}</Link>
      </li>
    ))}
    <DonateButton />
  </ul>
)

const Sidebar = ({ sidebarOpen }) => (
  <aside className={cls(styles.sidebar, sidebarOpen && styles.sidebarOpen)} >
    <NavigationItems />
  </aside>
)

const Navigation = () => {
  const navRef = React.useRef()
  const [shrink, setShrink] = React.useState(false)
  const [sidebarOpen, setSidebarOpen] = React.useState(false)
  const toggleSideBar = () => setSidebarOpen(!sidebarOpen)

  React.useEffect(() => {
    const navElement = navRef.current

    function onScroll() {
      const navIsFixed = getComputedStyle(navElement).position === 'fixed'
      if (!navIsFixed) return setShrink(false)
      setShrink(window.scrollY >= 250)
    }

    window.addEventListener('scroll', onScroll)
    window.addEventListener('resize', onScroll)

    return () => {
      window.removeEventListener('scroll', onScroll)
      window.removeEventListener('resize', onScroll)
    }
  })

  return (
    <nav
      ref={navRef}
      className={`${styles.navBar} ${shrink ? styles.shrink : ''}`}
    >
      <Link to="/">
        <DSALogo />
      </Link>
      <div className={styles.desktopNavigationLinks}>
        <NavigationItems />
      </div>
      <button
        className={`${styles.toggleButton} ${
          sidebarOpen ? styles.sidebarOpen : ''
        }`}
        onClick={toggleSideBar}
      >
        <span className={styles.hamburger} />
      </button>
      <Sidebar sidebarOpen={sidebarOpen} />
    </nav>
  )
}

export default Navigation
