// extracted by mini-css-extract-plugin
export const redbackgroundCard = "cards-module--redbackgroundCard--o7tr1";
export const fullWidthCard = "cards-module--fullWidthCard--1qvtk";
export const redbackgroundCardOuterBorder = "cards-module--redbackgroundCardOuterBorder--3BKjP";
export const redbackgroundCardInnerBorder = "cards-module--redbackgroundCardInnerBorder--2_9qM";
export const leftRightCards = "cards-module--leftRightCards--1D4w_";
export const imageCard = "cards-module--imageCard--bYeWw";
export const cardAuthor = "cards-module--card-author--RDAo9";
export const cardDate = "cards-module--card-date--2ELFr";
export const fullWidthVerticallyCenteredCard = "cards-module--fullWidthVerticallyCenteredCard--3AgMZ";
export const pictureCards = "cards-module--pictureCards--3JQ38";
export const pictureCard = "cards-module--pictureCard--2YkUi";
export const pictureCardText = "cards-module--pictureCardText--3f43s";