import React from "react";

const TikTok = ({ color="white", ...styles }) => {
  return (
    <svg
      {...styles}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5747 0H2.37497C1.06541 0 0 1.06899 0 2.38295V17.6339C0 18.9478 1.06541 20.0168 2.37497 20.0168H17.5747C18.8843 20.0168 19.9497 18.9478 19.9497 17.6339V2.38295C19.9497 1.06899 18.8843 0 17.5747 0ZM15.6776 8.73258C15.5698 8.74258 15.4605 8.74926 15.3499 8.74926C14.104 8.74926 13.0091 8.10634 12.3721 7.13457C12.3721 9.68385 12.3721 12.5844 12.3721 12.633C12.3721 14.8773 10.5586 16.6969 8.32188 16.6969C6.08514 16.6969 4.27161 14.8773 4.27161 12.633C4.27161 10.3887 6.08514 8.56911 8.32188 8.56911C8.40643 8.56911 8.48908 8.57673 8.5722 8.58197V10.5846C8.48908 10.5746 8.40738 10.5593 8.32188 10.5593C7.17999 10.5593 6.25471 11.4877 6.25471 12.6335C6.25471 13.7792 7.17999 14.7076 8.32188 14.7076C9.46376 14.7076 10.4722 13.8049 10.4722 12.6592C10.4722 12.6139 10.4921 3.32089 10.4921 3.32089H12.3997C12.5792 5.03232 13.9562 6.38298 15.6776 6.5069V8.73258Z"
        fill={color}
      />
    </svg>
  );
};

export default TikTok;
