import React from "react";

const LinkedIn = ({ color="white", ...styles }) => {
  return (
    <svg
      {...styles}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9565 0H0.913043C0.391304 0 0 0.381048 0 0.889113V19.5605C0 19.9415 0.391304 20.3226 0.913043 20.3226H20.087C20.6087 20.3226 21 19.9415 21 19.4335V0.889113C20.8696 0.381048 20.4783 0 19.9565 0ZM6.13043 17.2742H3.13043V7.62097H6.26087V17.2742H6.13043ZM4.69565 6.35081C3.65217 6.35081 2.86957 5.46169 2.86957 4.57258C2.86957 3.55645 3.65217 2.79435 4.69565 2.79435C5.73913 2.79435 6.52174 3.55645 6.52174 4.57258C6.3913 5.46169 5.6087 6.35081 4.69565 6.35081ZM17.7391 17.2742H14.6087V12.5746C14.6087 11.4315 14.6087 10.0343 13.0435 10.0343C11.4783 10.0343 11.2174 11.3044 11.2174 12.5746V17.4012H8.08696V7.62097H11.087V8.89113C11.4783 8.12903 12.5217 7.36694 13.9565 7.36694C17.087 7.36694 17.6087 9.39919 17.6087 11.9395V17.2742H17.7391Z"
        fill={color}
      />
    </svg>
  );
};

export default LinkedIn;
