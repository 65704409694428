import PassThroughComponent from '../components/pass-through-component'
import * as styles from './typography.module.css'


export const CardHeader = PassThroughComponent('h1', styles.cardHeader)

export const SmallCardHeader = PassThroughComponent('h1', styles.smallCardHeader)

export const BigParagraph = PassThroughComponent('p', styles.bigParagraph)

export const MediumParagraph = PassThroughComponent('p', styles.mediumParagraph)
