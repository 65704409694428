import React from "react";

const Email = ({ color = "white", ...styles }) => {
  return (
    <svg
      {...styles}
      viewBox="0 0 23 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.7 0H2.3C1.035 0 0.0115 1.01932 0.0115 2.26515L0 15.8561C0 17.1019 1.035 18.1212 2.3 18.1212H20.7C21.965 18.1212 23 17.1019 23 15.8561V2.26515C23 1.01932 21.965 0 20.7 0ZM20.7 4.5303L11.5 10.1932L2.3 4.5303V2.26515L11.5 7.92803L20.7 2.26515V4.5303Z"
        fill={color}
      />
    </svg>
  );
};

export default Email;
