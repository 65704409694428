import React from "react"
import { Link } from "gatsby"
import {
  Email,
  Facebook,
  Instagram,
  LinkedIn,
  TikTok,
} from "./social-media-icons"
import * as styles from "./footer.module.css"
import MHIDotsLogo from './mhi-dots-logo'

const BuiltWith = () => {
  return (
    <div className={styles.builtWith}>
      <span style={{ display: 'flex', alignItems: 'center' }}>
        Built with
        <svg width="33" height="17" viewBox="0 0 20 19" fill="none">
          <path
            d="M14.5 0C11.605 0 10 2.09 10 2.09C10 2.09 8.395 0 5.5 0C2.462 0 0 2.462 0 5.5C0 9.671 4.912 13.713 6.281 14.99C7.858 16.46 10 18.35 10 18.35C10 18.35 12.142 16.46 13.719 14.99C15.088 13.713 20 9.671 20 5.5C20 2.462 17.538 0 14.5 0Z"
            fill="#FA759E"
          />
        </svg>
        by the team at
      </span>
      <a href="https://morehumaninternet.org/">
        <MHIDotsLogo />
      </a>
    </div>
  )
}

const Footer = () => {
  const socialMedia = [
    { url: "mailto:info@dreamsportsafrica.org", icon: <Email color="#282862" width={30} /> },
    { url: "https://facebook.com/dreamsportsafrica", icon: <Facebook color="#282862" width={18} /> },
    { url: "https://instagram.com/dreamsportsafrica", icon: <Instagram color="#282862" width={30} /> },
    {
      url: "https://www.linkedin.com/company/dream-sports-africa",
      icon: <LinkedIn color="#282862" width={30} />,
    },
    { url: "https://tiktok.com/@dreamsportsafrica", icon: <TikTok color="#282862" width={30} /> },
  ]

  return (
    <footer>
      <div className={styles.footerLinks}>
        <div className={styles.footerLogos}>
          Connect With Us
          <div className={styles.socialMediaContainer}>
            {socialMedia.map((l, i) => (
              <a
                key={`socialmedia-${i}`}
                className={styles.socialMediaLink}
                href={l.url}
                target="_blank"
                rel="noreferrer"
              >
                {l.icon}
              </a>
            ))}
          </div>
        </div>

        <div className={styles.linkSection}>
          <div className={styles.linkGroup}>
            <Link to="/who-we-are">Who We Are</Link>
            <Link to="/who-we-are#our-beliefs">Our Beliefs</Link>
            <Link to="/who-we-are#our-motivation">Our Motivation</Link>
            <Link to="/who-we-are#our-focus">Our Focus</Link>
            <Link to="/who-we-are#our-dream-team">Our Dream Team</Link>
          </div>
          <div className={styles.linkGroup}>
            <Link to="/highlights">Highlights</Link>
          </div>
          <div className={styles.linkGroup}>
            <Link to="/contact-us">Contact Us</Link>
          </div>
        </div>
        <div className={styles.linkSection}>
          <div className={styles.linkGroup}>
            <Link to="/what-we-do">What We Do</Link>
            <Link to="/what-we-do#basketball-experience">Basketball Experience</Link>
            <Link to="/what-we-do#naijacore">NaijaCore</Link>
            <Link to="/what-we-do#coach-activation">Coach Activation</Link>
            <Link to="/what-we-do#our-framework">Our Framework</Link>
            <Link to="/what-we-do#our-curriculum">Our Curriculum</Link>
          </div>
          <div className={styles.linkGroup}>
            <Link to="/donate">Donate</Link>
          </div>
        </div>
        <BuiltWith />
      </div>
      <div className={styles.copyright}>
        <div>© Dream Sports Africa {new Date().getFullYear()}</div>
        <div>
          Dream Sports Africa is a registered 501(c)(3) organization
        </div>
      </div>
    </footer>
  )
}

export default Footer
