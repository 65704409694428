import React from 'react'
import { Link } from 'gatsby'
import * as styles from './donate-button.module.css'

const DonateButton = () => (
  <Link to="/donate" role="button" className={styles.donateButton}>
    Donate
  </Link>
)

export default DonateButton
