// extracted by mini-css-extract-plugin
export const navBar = "navigation-module--navBar--3DxVl";
export const dsaLogo = "navigation-module--dsaLogo--1tniT";
export const toggleButton = "navigation-module--toggleButton--mJQ51";
export const navigationItems = "navigation-module--navigationItems--1irGm";
export const navigationItem = "navigation-module--navigationItem--1MDqc";
export const sidebarOpen = "navigation-module--sidebarOpen--er8df";
export const hamburger = "navigation-module--hamburger--15mLT";
export const sidebar = "navigation-module--sidebar--3hIZ4";
export const shrink = "navigation-module--shrink--2XL3c";
export const desktopNavigationLinks = "navigation-module--desktopNavigationLinks--16_L8";