import React from 'react'
import { identity } from 'lodash'
import * as cls from 'classnames'


export default function PassThroughComponent(TagName, alwaysClassName, withChildren = identity) {
  return React.forwardRef(
    ({ children, className, ...props }, ref) => (
      <TagName
        ref={ref}
        className={cls(alwaysClassName, className)}
        {...props}
      >
        {withChildren(children)}
      </TagName>
    )
  )
}

// export default function PassThroughComponent(TagName, alwaysClassName) {
//   return React.forwardRef(
//     ({ children, className, ...props }, ref) => (
//       <TagName
//         ref={ref}
//         className={cls(alwaysClassName, className)}
//         {...props}
//       >
//         {children}
//       </TagName>
//     )
//   )
// }
