import React from "react";

const Instagram = ({ color="white", ...styles }) => {
  return (
    <svg
      {...styles}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.41797"
        y="1.28076"
        width="18.5821"
        height="18.3474"
        rx="3.32"
        stroke={color}
        strokeWidth="1.328"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9978 9.82698C15.2722 11.6538 14.3291 13.4485 12.6583 14.2788C10.9876 15.1091 8.9659 14.7879 7.6433 13.482C6.32069 12.1761 5.99537 10.1799 6.83631 8.53027C7.67724 6.88063 9.49483 5.94943 11.345 6.22033C13.2343 6.49694 14.7177 7.96161 14.9978 9.82698Z"
        stroke={color}
        strokeWidth="1.328"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6581 5.56226H15.6705"
        stroke={color}
        strokeWidth="1.328"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Instagram;
