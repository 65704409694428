// extracted by mini-css-extract-plugin
export const mhiWithTextLogo = "mhi-dots-logo-module--mhi-with-text-logo--3pEcG";
export const headerLogo = "mhi-dots-logo-module--header-logo--jlVLI";
export const human = "mhi-dots-logo-module--human--2Y7rb";
export const leftHead = "mhi-dots-logo-module--left-head--1PtCI";
export const topRight = "mhi-dots-logo-module--top-right--UvRE-";
export const againstHero = "mhi-dots-logo-module--against-hero--1gfcd";
export const more = "mhi-dots-logo-module--more--1uNih";
export const internet = "mhi-dots-logo-module--internet--1ldHS";
export const bottomLeft = "mhi-dots-logo-module--bottom-left--2Mduh";
export const againstWhiteBg = "mhi-dots-logo-module--against-white-bg--2rExI";