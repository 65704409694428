import React from "react";

const Facebook = ({ color="white", ...styles }) => {
  return (
    <svg
      {...styles}
      viewBox="0 0 14 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0075 0H9.45997C6.1946 0 3.54749 2.60123 3.54749 5.81V9.296H0V13.944H3.54749V23.24H8.27748V13.944H11.825L13.0075 9.296H8.27748V5.81C8.27748 5.16825 8.8069 4.648 9.45997 4.648H13.0075V0Z"
        fill={color}
      />
    </svg>
  );
};

export default Facebook;
