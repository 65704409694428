import React from 'react'
import {StaticImage} from 'gatsby-plugin-image'
import * as styles from './donate.module.css'
import DonateButton from '../donate-button'
import useExplicitHeightOnIphone from '../../effects/useExplicitHeightOnIphone'


const Donate = () => (
  <div
    ref={useExplicitHeightOnIphone(React.useRef())}
    className={styles.donateSection}
  >
    <StaticImage 
      src="../../images/soccer-stretches.png" alt="soccer stretches" 
      className={styles.donateBackgroundImageContainer}
      imgClassName={styles.donateBackgroundImage}
    />
    <div className={styles.donateContent}>
      <p className={styles.donateMessage}>
        Your support transforms lives for African youth
      </p>
      <DonateButton />
    </div>
  </div>
)

export default Donate
