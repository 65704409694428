import React from 'react'
import Helmet from 'react-helmet'
import './reset.css'
import './base.css'
import { LeftRightImageCards } from "../components/cards"
import Donate from '../components/donate'
import Navigation from '../components/navigation'
import Footer from '../components/footer'
import { last } from 'lodash'

const Layout = ({ children, excludeDonateAndSignup }) => (
  <>
    <Navigation />
    <div style={{
      minHeight: 'calc(100vh - var(--navbar-height))',
      display: 'grid',
      gridTemplateRows: "1fr auto"
    }}>
      <main>
        {children}
        {!excludeDonateAndSignup && <>
          <Donate />
        </>}
      </main>
      <Footer />
    </div>
  </>
)

export const StandardLayout = ({
  title,
  subtitle,
  backgroundColor,
  titleColor,
  subtitleColor,
  subtitleFont,
  img,
  ogImageSrc,
  scripts,
  smallCardHeader,
  bigSubtitle,
  excludeDonateAndSignup,
  children,
}) => {

  const path = last(ogImageSrc.split('/'))
  const fileType = path.match(/\.(\w+)/)[1]

  return (
    <Layout excludeDonateAndSignup={excludeDonateAndSignup}>
      <Helmet title={`${title} | Dream Sports Africa`}>
        <meta property='og:title' content={`${title} | Dream Sports Africa`}/>
        <meta
          property='og:description'
          content={subtitle}
        />
        <meta name='twitter:title' content='Dream Sports Africa' />
        <meta
          name='twitter:description'
          content={subtitle}
        />
        <meta name="msapplication-TileImage" content={ogImageSrc} />

        <meta property='og:local' content="en_US" />
        <meta property='og:image' itemprop="image" content={ogImageSrc} />
        <meta property="og:image:type" content={`image/${fileType}`} />
        <meta property="og:image:height" content="256" />
        <meta property="og:image:width" content="256" />

        <meta property="og:site_name" content={`${title} | Dream Sports Africa`} />
        <meta property="og:type" content="website" />
        <meta property="og:updated_time" content={Math.floor(Date.now() / 1000)} />

        {scripts}
      </Helmet>

      <LeftRightImageCards
        title={title}
        smallCardHeader={smallCardHeader}
        subtitle={subtitle}
        backgroundColor={backgroundColor}
        titleColor={titleColor}
        subtitleColor={subtitleColor}
        subtitleFont={subtitleFont}
        img={img}
        bigSubtitle={bigSubtitle}
      />
      <div /* className={styles.wrapper} */>
        {children}
      </div>
    </Layout>
  )
}

export default Layout
