import React from "react"
import * as cls from 'classnames'
import PassThroughComponent from "../pass-through-component"
import { CardHeader, SmallCardHeader, MediumParagraph, BigParagraph } from '../../typography'
import * as colors from '../../colors.module.css'
import * as styles from "./cards.module.css"

export const RedbackgroundCard = PassThroughComponent('div', styles.redbackgroundCard, children => (
  <div className={styles.redbackgroundCardOuterBorder}>
    <div className={styles.redbackgroundCardInnerBorder}>{children}</div>
  </div>
))

export const LeftRightCards = PassThroughComponent('div', styles.leftRightCards)

export const FullWidthCard = PassThroughComponent('div', styles.fullWidthCard)

export const ImageCard = PassThroughComponent('div', styles.imageCard)

export const FullWidthVerticallyCenteredCard = PassThroughComponent(FullWidthCard, styles.fullWidthVerticallyCenteredCard)

export const LeftRightImageCards = ({
  title,
  subtitle,
  backgroundColor,
  titleColor,
  subtitleColor,
  subtitleFont,
  img,
  smallCardHeader = false,
  bigSubtitle = false,
  imageFirst = false,
  className,
  ...props
}) => {
  const SubtitleComponent = bigSubtitle ? BigParagraph : MediumParagraph
  const subtitleEl = subtitle && (
    <SubtitleComponent className={cls(subtitleFont, subtitleColor || colors.white)} key="SubtitleComponent">{subtitle}</SubtitleComponent>
  )

  const HeaderComponent = smallCardHeader ? SmallCardHeader : CardHeader
  const textCard = (
    <RedbackgroundCard className={backgroundColor} key="redBackgroundCard">
      <HeaderComponent
        className={titleColor}
        style={subtitle ? {} : { marginBottom: 0 }}
      >
        {title}
      </HeaderComponent>
      {subtitleEl}
    </RedbackgroundCard>
  )

  const imageCard = (
    <ImageCard key="imageCard">
      {img}
    </ImageCard>
  )

  const innerCards = imageFirst
    ? [imageCard, textCard]
    : [textCard, imageCard]

  return (
    <LeftRightCards className={className} {...props}>
      {innerCards}
    </LeftRightCards>
  )
}

export const PictureCardText = PassThroughComponent('div', styles.pictureCardText)

export const PictureCard = ({
  children,
  className,
  img,
  ...props
}) => (
  <div className={cls(className, styles.pictureCard)} {...props} >
    {img}
    {children}
  </div>
)

export const PictureCardWithInteriorBorder = ({
  children,
  className,
  contentsClassName,
  color,
  img,
  ...props
}) => (
  <PictureCard
    className={className}
    img={img}
    {...props}
  >
    <PictureCardText
      style={{ borderTop: `20px solid ${color}` }}
      className={contentsClassName}
    >
      {children}
    </PictureCardText>

  </PictureCard>
)

export const PictureCards = PassThroughComponent('div', styles.pictureCards)
